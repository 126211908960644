import Head from 'next/head'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { trimUnescapedHtml, baseUrl } from 'utils/actions'

export const staticUrl = `${baseUrl}/static`
const siteName = 'Staking Rewards'
const twitterHandle = '@StakingRewards'

export function SeoTags({
    canonicalPath = null,
    title = 'The Staking Explorer',
    description = 'Staking Rewards is the central information hub and leading data aggregator for the rapidly growing $300B+ crypto staking industry...',
    mainImg = `${staticUrl}/img/social-twitter-main-1200x675.png`,
    calcImg = `${staticUrl}/img/staking_calculator_video_img.jpeg`,
    // googleDescription = description, Commented out google, facebook, twitter for now as we're now using dynamic descriptions based on page content
    googleImg = mainImg,
    // facebookDescription = description,
    facebookImg = mainImg,
    twitterCardType = 'summary_large_image',
    twitterCreator = twitterHandle,
    // twitterDescription = description,
    twitterImg = mainImg,
    noIndex = 'noindex, nofollow',
    children = null,
    useFullTitle = true,
    twitterTitle = title,
}) {
    const router = useRouter()

    const url =
        canonicalPath === 'none'
            ? undefined
            : canonicalPath || `${baseUrl}${router?.asPath}`

    const fullTitle = `${title}`

    return (
        <>
            <Head>
                {/* HTML Meta Tags */}
                <meta key='charSet' charSet='utf-8' />
                <meta
                    key='httpEquiv'
                    httpEquiv='X-UA-Compatible'
                    content='IE=edge'
                />

                <title key='title'>{`${trimUnescapedHtml(
                    useFullTitle ? fullTitle : title
                )}`}</title>

                <meta
                    key='description'
                    name='description'
                    content={trimUnescapedHtml(description)}
                />

                <meta
                    key='viewport'
                    name='viewport'
                    content='width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=5'
                />

                <link key='manifest' rel='manifest' href='/manifest.json' />

                <link
                    key='apple-touch-icon'
                    rel='apple-touch-icon'
                    href='/static/icons/icon-512.png'
                />

                <meta key='theme-color' name='theme-color' content='#161616' />

                <meta key='robots' name='robots' content={noIndex} />

                <link rel='canonical' href={url} key='canonical' />
                {/* schema mark up */}

                {/* Google / Search Engine Tags  */}
                <meta
                    key='google:name'
                    itemProp='name'
                    content={trimUnescapedHtml(fullTitle)}
                />
                <meta
                    key='google:description'
                    itemProp='description'
                    content={trimUnescapedHtml(description)}
                />
                <meta key='google:image' itemProp='image' content={googleImg} />

                {/* Facebook Meta Tags */}
                <meta key='fb:url' property='og:url' content={url} />
                <meta key='fb:type' property='og:type' content='website' />
                <meta
                    key='fb:site_name'
                    property='og:site_name'
                    content={siteName}
                />
                <meta
                    key='fb:title'
                    property='og:title'
                    content={trimUnescapedHtml(fullTitle)}
                />
                <meta
                    key='fb:description'
                    property='og:description'
                    content={trimUnescapedHtml(description)}
                />
                <meta
                    key='fb:image'
                    property='og:image'
                    content={facebookImg}
                />

                {/* TWITTER */}
                <meta
                    key='twitter:card'
                    name='twitter:card'
                    content={twitterCardType}
                />
                <meta
                    key='twitter:creator'
                    name='twitter:creator'
                    content={twitterCreator}
                />
                <meta
                    key='twitter:site'
                    name='twitter:site'
                    content={twitterHandle}
                />
                <meta
                    key='twitter:title'
                    name='twitter:title'
                    content={trimUnescapedHtml(twitterTitle)}
                />
                <meta
                    key='twitter:description'
                    name='twitter:description'
                    content={trimUnescapedHtml(description)}
                />
                <meta
                    key='twitter:image'
                    name='twitter:image'
                    content={twitterImg}
                />

                {children}
            </Head>
            {/* Safary SDK Script */}
            <Script
                id='safary-sdk'
                dangerouslySetInnerHTML={{
                    __html: `
                    var script=document.createElement('script');
                    script.src="https://tag.safary.club/stag-0.1.11.js";
                    script.async=true;
                    script.setAttribute('data-name','safary-sdk');
                    script.setAttribute('data-product-id','prd_Bw0agcJGfj');
                    script.integrity="sha256-FcvJgRCaJFszFBKv8MGIbUcmHlVuHp7LX8zGgjfvvGs=";
                    script.crossOrigin="anonymous";
                    var target=document.head||document.body;
                    target.appendChild(script);
                    `,
                }}
            />
        </>
    )
}
