export const FRONT_PAGE_KEY = 'journal'

export const JOURNAL_PAGES = [
    {
        key: FRONT_PAGE_KEY,
        name: 'Journal',
        seoTitle: 'Proof of Stake Crypto Research Journal',
        seoDescription:
            'An open collection of crypto staking research, data insights, thought leadership, industry news, and staking guides curated by our research team.',
    },
    {
        key: 'research',
        name: 'Research',
        seoTitle: 'Staking Crypto Research',
        seoDescription:
            "Staking Rewards' research articles provide data-driven, insightful analysis and commentary on relevant topics within the staking industry... Learn more.",
    },
    {
        key: 'guides',
        name: 'Guides',
        seoTitle: 'Crypto Staking Guides and How to Tutorials',
        seoDescription:
            'Wonder how to stake step by step? Read the Staking guides for the top stakeable (PoS) crypto assets to learn the tricks and best practices when staking your crypto. ',
    },
    {
        key: 'infographics',
        name: 'Infographics',
        seoTitle: 'Crypto Infographics and Ecosystems',
        seoDescription:
            'Explore the top narratives shaping the future of crypto. Get a clearer understanding of Restaking, Bitcoin Staking, Liquid Staking and more.',
    },
    {
        key: 'interviews',
        name: 'Interviews',
        seoTitle: 'Proof of Stake Crypto Interviews',
        seoDescription:
            'Together with the Founders and Teams behind the top stakeable (Proof of Stake) crypto assets, we explore Staking Economics and Macro Blockchain Trends. Read and dive deep now.',
    },
    {
        key: 'news',
        name: 'News',
        seoTitle: 'Staking Rewards Company News',
        seoDescription:
            'Read the latest news and updates from Staking Rewards, covering new staking tools, research and development and company roadmap.',
    },
    {
        key: 'staking-insider',
        name: 'Staking Insider',
        seoTitle: 'Weekly Staking Insider Alpha',
        seoDescription:
            'Weekly publication exploring cutting-edge alpha and actionable financial insights to the crypto tales of staking markets. Read the research and weekly state of the staking now.',
    },
]

export const TAG_FEATURED = 'Featured'
