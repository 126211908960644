import { fetchStrapiContent } from 'data/cmsQueries'
import { useEffect, useState } from 'react'

const initialState = { 0: [] }

export function useJournalPosts(
    category = '',
    start = 0,
    limit = 5,
    tag = '',
    isReady = false
) {
    const [isLoading, setLoading] = useState(true)
    const [data, setData] = useState(null)
    const [error, setError] = useState(null)

    useEffect(() => {
        if (!isReady) return

        setLoading(true)
        const categoryFilter = category
            ? `categories: {name: { eq: "${category}" }}`
            : ''
        const tagType = tag === 'Featured' ? 'name' : 'slug'
        const tagFilter = tag ? `tags: {${tagType}: { eq: "${tag}" }} ` : ''
        fetchStrapiContent(
            [categoryFilter, tagFilter].filter(Boolean).join(', '),
            start,
            limit
        )
            .then(response => response.json())
            .then(fetchedData => {
                setData(fetchedData?.data?.posts?.data ?? [])
                setLoading(false)
            })
            .catch(fetchError => {
                setError(fetchError)
                setLoading(false)
            })
    }, [category, limit, start, tag, isReady])

    return { data, isLoading, error }
}

export function usePaginatedJournalPosts(
    category = '',
    start = 0,
    limit = 12,
    tag = '',
    isReady = false
) {
    const [paginationPage, setPaginationPage] = useState(0)
    const { data, isLoading, error } = useJournalPosts(
        category,
        start + paginationPage * limit,
        limit,
        tag,
        isReady
    )
    const [batches, setBatches] = useState(initialState)

    useEffect(() => {
        setBatches(batches => ({ ...batches, [paginationPage]: data }))
    }, [paginationPage, data])

    useEffect(() => {
        if (paginationPage === 0) {
            setBatches(initialState)
        }
    }, [paginationPage])

    return { batches, isLoading, error, paginationPage, setPaginationPage }
}

/**
 * Custom hook to augment the provided HTML content by adding unique `id` attributes to all `<h2>` elements.
 *
 * This hook is designed to process HTML content (typically received from a CMS or similar data source)
 * and assign unique `id` attributes to every `<h2>` element found within. This enhancement allows
 * for functionalities like table of contents, deep linking, or smooth scrolling to these headings.
 *
 * The assignment uses the format `h2-INDEX`, where INDEX is the zero-based index of the `<h2>`
 * element in the document order.
 *
 * @param {string} htmlContent - The original HTML content that requires `<h2>` enhancements.
 *
 * @returns {string} - Updated HTML content with unique `id` attributes on `<h2>` elements.
 *
 * @example
 * const updatedHtml = useUpdatedHtmlWithH2Ids(originalHtmlContent);
 * // Render the updatedHtml in the component, enabling enhanced navigation.
 *
 * Note: This hook assumes it's being used in a browser environment, as it leverages DOMParser
 * and document.createElement. Make sure it's not invoked server-side or in contexts where
 * the DOM isn't accessible.
 */
export const useUpdatedHtmlWithH2Ids = (htmlContent = '') => {
    const [updatedHtml, setUpdatedHtml] = useState('')

    useEffect(() => {
        const updateHtml = () => {
            const isBrowser = typeof window !== 'undefined'
            if (!isBrowser) {
                return htmlContent
            }

            const parser = new DOMParser()
            const doc = parser.parseFromString(htmlContent, 'text/html')
            const h2Elements = doc.querySelectorAll('h2')
            h2Elements.forEach((h2, idx) => {
                const replacementElement = document.createElement('h2')
                replacementElement.id = `h2-${idx}`
                replacementElement.textContent = h2.textContent
                h2.parentNode.replaceChild(replacementElement, h2)
            })
            return doc.body.innerHTML
        }

        setUpdatedHtml(updateHtml())
    }, [htmlContent])

    return updatedHtml
}
